import Axios from "./Axios";

class WhitelistService {

    /**
     * Add organization whitelist
     *
     * @param item
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeOrgWhitelist(item) {
        return Axios({method: "post", url: 'organisation-whitelist', data: item});
    }
}

export default new WhitelistService();